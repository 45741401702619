.form-modal {
  :global(.anticon) {
    svg {
      @apply tw-align-bottom #{!important};
    }
  }

  :global(.ant-modal-header) {
    @apply tw-pl-6 tw-py-2.5 #{!important};
  }

  :global(.ant-modal-title) {
    @apply tw-header-5 tw-text-gray-9 #{!important};
  }

  :global(.ant-modal-close-x) {
    @apply tw-w-10 tw-h-10 #{!important};
  }

  :global(.ant-modal-close) {
    @apply tw-w-10 tw-h-10 #{!important};
  }
}

.container {
  :global(.ant-form-item-label) {
    @apply tw-flex tw-items-center tw-text-left tw-text-sm tw-mr-2.5 tw-h-8 tw-font-bold;
    min-width: 100px;
  }
}
