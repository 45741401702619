.container {
  @apply tw-flex-col tw-space-y-6;
}

.empty {
  @apply tw-flex tw-h-72 tw-justify-center tw-items-center;
}

.suncare-header {
  @apply tw-flex tw-items-center;
}

.suncare-name {
  @apply tw-header-3 tw-text-gray-9 tw-mr-3;
}

.suncare-description {
  @apply tw-body-3 tw-text-gray-6;
}

.suncare-radio-container {
  @apply tw-pt-5;
}
