.issued-task-container {
  :global(.button-progress) {
    @apply tw-text-sm tw-font-bold tw-text-white tw-border-0 tw-w-full tw-p-0;
  }
  :global(.button-progress.new) {
    @apply tw-bg-green-2 #{!important};
  }
  :global(.button-progress.sent) {
    @apply tw-bg-red-2 #{!important};
  }
  :global(.button-progress.done) {
    @apply tw-bg-blue-sub3 #{!important};
  }
}
