.header {
  @apply tw-flex tw-items-center;

  .name {
    @apply tw-header-3 tw-text-gray-9 tw-mr-3;
  }

  .description {
    @apply tw-body-3 tw-text-gray-6;
  }

  .right-container {
    @apply tw-ml-auto;
  }

  .delete-button {
    @apply tw-mr-3;
  }
}

.empty-container {
  @apply tw-flex 
  tw-text-black 
  tw-h-28 
  tw-justify-center 
  tw-items-center;
}

.table {
  @apply tw-mt-6;

  .description {
    @apply tw-w-96;
  }
}

.suspended-care-item {
  @apply tw-bg-red-1;
}

.suspended {
  :global(.ant-table-thead > tr > th) {
    @apply tw-bg-red-2;
  }
}
