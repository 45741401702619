.dialog-user-form {
  .footer button[type="submit"] {
    @apply tw-ml-2.5;
  }

  .ant-modal-body h2 {
    @apply tw-text-base tw-font-bold tw-text-gray-9 tw-mb-5;
  }
}

.modal-completion-title-wrapper {
  @apply tw-flex tw-justify-start tw-items-center;
  .modal-completion-title-icon {
    @apply tw-text-base tw-text-blue-primary;
  }
  .modal-completion-title {
    @apply tw-text-base tw-text-gray-9;
  }
}
