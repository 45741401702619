.truncated-text-box {
  @apply tw-relative;
  word-wrap: break-word;
  line-height: 1.375rem;

  &.is-more {
    overflow: initial;
    text-overflow: initial;
    -webkit-line-clamp: initial;
    -webkit-box-orient: initial;
    height: auto;

    .content {
      white-space: pre-line;
      overflow-y: scroll;
      width: initial;
      max-height: 6.875rem;
    }

    .content::-webkit-scrollbar {
      display: none;
    }
  }

  &.is-less {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    height: 1.375rem;

    .content {
      white-space: initial;
      width: calc(100% - 40px);
    }
  }

  .btn {
    @apply tw-header-5;
  }
  .btn-more {
    @apply tw-hidden tw-absolute;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }
  &.is-less .btn-more {
    @apply tw-block;
  }

  .btn-less {
    @apply tw-hidden tw-mt-2.5;
  }
  &.is-more .btn-less {
    @apply tw-block;
  }
}
