.curriculum-select {
  @apply tw-max-w-lg #{!important};
}

.curriculum-form-item {
  @apply tw-mb-4 #{!important};
}

.duration {
  @apply tw-w-20 #{!important};
}
