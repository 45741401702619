.tabs-container {
  :global(.ant-tabs-nav) {
    @apply tw-bg-white tw-ml-2.5 #{!important};
  }
  :global(.ant-tabs-right > .ant-tabs-nav) {
    @apply tw-border-t-0 tw-border-r-0 tw-border-b-0 tw-h-1/2 #{!important};
  }
  :global(.ant-tabs-nav-operations.ant-tabs-nav-operations) {
    @apply tw-hidden #{!important};
  }
  .tab-title {
    @apply tw-text-gray-9 tw-font-black tw-mb-5;
  }
}
