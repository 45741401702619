.noti-modal {
  :global(.ant-modal-header) {
    @apply tw-border-b-0 tw-pt-6 tw-pb-2 #{!important};
  }

  :global(.ant-modal-body) {
    @apply tw-pt-0 tw-pb-0 tw-pl-16 tw-pr-6 #{!important};
  }

  :global(.ant-modal-footer) {
    @apply tw-border-t-0 tw-p-6 #{!important};
  }
}

.icon-base {
  @apply tw-text-xl tw-m-0 tw-p-0 #{!important};
  svg {
    @apply tw-align-text-top #{!important};
  }
}

.icon-warn {
  @apply icon-base tw-text-yellow-3 #{!important};
}

.icon-confirm {
  @apply icon-base tw-text-blue-primary #{!important};
}

.icon-error {
  @apply icon-base tw-text-red-3 #{!important};
}

.title {
  @apply tw-header-4 tw-text-gray-9 #{!important};
}

.content {
  @apply tw-body-3 tw-text-gray-9 tw-whitespace-pre-line #{!important};
}
