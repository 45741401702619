.activity-calendar {
  :global(.ant-picker-calendar-mode-switch) {
    display: none;
  }

  :global(.ant-picker-calendar-header) {
    padding: 0 0 12px 0;
  }

  :global(.ant-picker-calendar-date-content) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
  }

  :global(.activity-progress) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    color: #ffffff;
    border-radius: 50%;
  }

  :global(.fulfill-status) {
    background: #52C41A;
  }

  :global(.normal-status) {
    background: #FFC542;
  }

  :global(.lack-status) {
    background: #F5222D;
  }

  :global(.ant-today-button) {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 8px;
    max-width: 60px;
    min-width: 60px;
    max-height: 32px;
    min-height: 32px;
    border-radius: 2px;
    background: #2749C2;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
  }
}