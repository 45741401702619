.progress-tabs {
  :global(.ant-tabs-tabpane) {
    @apply tw-p-0 #{!important};
  }
  :global(.ant-tabs-nav) {
    @apply tw-bg-white tw-font-bold #{!important};
    @apply tw-border-l-0 tw-border-r-0 tw-border-t-0 #{!important};
    @apply tw-ml-0 #{!important};
    margin-bottom: 25px !important;
  }
}
