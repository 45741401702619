.menunav-container {
  @apply tw-bg-blue-primary tw-border-none #{!important};

  .sub-menu-container {
    @apply tw-text-white tw-border-none #{!important};
  }

  .sub-menu-container .ant-menu-submenu-title {
    @apply tw-body-2 hover:tw-text-white hover:tw-font-bold active:tw-bg-transparent #{!important};
  }

  .sub-menu-container svg {
    @apply tw-w-4 tw-h-4 tw-text-white tw-fill-current;
  }

  .sub-menu-container .ant-menu-sub.ant-menu-inline {
    @apply tw-bg-blue-sub6 #{!important};
  }

  .menu-item {
    @apply tw-text-white hover:tw-bg-blue-primary #{!important};
    position: relative;
  }

  .menu-item.ant-menu-item-selected {
    @apply tw-bg-blue-sub5 tw-font-bold #{!important};
  }

  .menu-item.ant-menu-item-selected a {
    @apply tw-font-bold #{!important};
  }

  .menu-item::after {
    @apply tw-border-r-0 #{!important};
  }

  .menu-item svg {
    @apply tw-w-4 tw-h-4;
  }

  .menu-item-typo {
    @apply tw-text-white tw-body-2 hover:tw-font-bold #{!important};
  }

  .sub-menu-item-typo {
    @apply tw-text-white tw-body-3 hover:tw-font-bold #{!important};
  }
  .task-noti {
    position: absolute;
    top: 11px;
    right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffc542;
    border-radius: 50%;
    max-width: 23px;
    min-width: 23px;
    max-height: 22px;
    min-width: 22px;
    color: #000000;
  }
}
