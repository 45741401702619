@import '~antd/dist/antd.less';

@primary-color: #244CDB;

@dropdown-prefix-cls: ~'@{ant-prefix}-dropdown';

.@{dropdown-prefix-cls} {
  .@{dropdown-prefix-cls}-menu-item-active {
    background-color: theme('backgroundColor.blueSub1');
    font-weight: theme('fontWeight.bold');
    font-size: theme('fontSize.sm');
  }
}

@primary-color: #244CDB;