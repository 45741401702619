.container {
  :global(.ant-select) {
    min-width: 170px !important;
    width: initial !important;
  }
  :global(.ant-picker) {
    min-width: 170px !important;
    width: initial !important;
  }

  :global(.ant-select) {
    min-width: 170px;
    width: initial;
  }

  :global(input.ant-input) {
    min-width: 318px;
    width: initial;
  }

  :global(input.ant-input-password) {
    min-width: 318px;
    width: initial;
  }

  :global(textarea.ant-input) {
    min-width: initial;
    width: 100%;
  }
}
