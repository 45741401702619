.user-edit-form-container {
  .form-item-wrapper {
    @apply tw-flex tw-justify-start tw-items-start tw-mb-5;
  }

  .ant-form-item-label {
    @apply tw-flex tw-items-center tw-text-left tw-text-sm tw-mr-2.5 tw-h-8;
    width: 100px;
  }

  .ant-select {
    width: 170px !important;
  }
  .ant-input, .ant-input-password {
    width: 318px !important;
  }

  .ant-row.ant-form-item {
    @apply tw-m-0;
  }

  .helpText {
    @apply tw-flex tw-items-center tw-text-gray-6 tw-text-xs;
    height: 32px;
  }
}
