.login-container {
  @apply tw-flex tw-items-center tw-flex-col;

  .page-title {
    @apply tw-header-1 tw-mb-6 tw-flex tw-justify-center tw-items-center;
  }

  .title-container {
    @apply tw-absolute tw-top-1/2 tw-transform tw--translate-y-72 tw-flex tw-flex-col;
  }

  .login-container {
    @apply tw-absolute tw-top-1/2 tw-transform tw--translate-y-32 tw-space-y-5 tw-w-96 tw-p-6 tw-overflow-auto tw-border tw-border-gray-3;
  }

  .base-form-container {
    @apply tw-flex tw-space-x-2 tw-px-4 tw-py-2 tw-items-center tw-border tw-rounded-sm tw-w-full;
  }

  .normal-form-container {
    @apply base-form-container tw-border-gray-4 hover:tw-border-blue-sub5 focus-within:tw-border-blue-sub5 focus-within:tw-shadow-input;
  }

  .error-form-container {
    @apply base-form-container tw-border-red-3;
  }

  .input {
    @apply tw-w-full tw-body-2 tw-outline-none;
  }

  .error-text {
    @apply tw-mx-4 tw-my-0 tw-self-start tw-body-4 tw-text-red-3 #{!important};
  }
}
