.descriptions {
  @apply tw-mt-4;

  :global(.ant-descriptions-item-label) {
    @apply tw-w-44 tw-header-5;
  }
  :global(.ant-descriptions-item-content) {
    @apply tw-w-40 tw-body-3;
  }
}

.submit-dates-button {
  @apply tw-ml-2.5;
}

.range-picker {
  :global(.anticon) {
    svg {
      @apply tw-align-baseline;
    }
  }
}
