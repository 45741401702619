.container {
  .input-name {
    min-width: 318px;
    width: initial;
  }
  .input-description {
    min-width: 522px;
    width: initial;
  }
}
