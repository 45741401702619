.activity-summary {
  .body {
    @apply tw-border tw-border-gray-3 tw-px-5 tw-py-6;
  }
  
  .text-box {
    display: flex;
    >span:first-child {
      display: block;
      max-width: 224px;
      min-width: 224px;
    }
  }

  .ant-select {
    @apply tw-ml-5;
    width: 120px;
  }
}