.dashboard-auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 123px 0 171px 0;
  :global(.dashboard-text) {
    margin: 48px 0 32px 0;
    font-weight: 900;
    font-size: 22px;
    line-height: 30px;
    color: #262626;
  }
  :global(.dashboard-btn) {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    >button {
      height: 32px;
      padding: 5px 10px;
      border-radius: 2px;
    }
  }
}
