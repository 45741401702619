.container {
  @apply tw-flex tw-space-x-2.5;
}

.icon {
  @apply tw-text-2xl tw-leading-none tw-text-red-3 #{!important};
}

.title {
  @apply tw-header-1 tw-text-gray-9 #{!important};
}
