.button-base {
  @apply tw-text-gray-9 hover:tw-bg-blue-sub2 active:tw-bg-blue-sub3 #{!important};
}

.button-assigned {
  @apply button-base tw-bg-blue-sub1 #{!important};
}

.button-assigned-none {
  @apply button-base tw-bg-gray-2 #{!important};
}

.menu-overlay {
  @apply tw-overflow-y-scroll;
  max-height: 80vh;
}
