.page-title {
  @apply tw-pb-5 tw-w-full tw-flex tw-justify-between tw-items-center;
}

.title-large {
  @apply tw-header-1;
}

.sub-title-large {
  @apply tw-body-3 tw-text-gray-6;
}

.title-normal {
  @apply tw-header-3;
}

.sub-title-normal {
  @apply tw-body-3 tw-text-gray-7;
}
