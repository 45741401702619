.activity-history {
  :global(.ant-space) {
    align-items: normal;
  }

  :global(.ant-layout) {
    background: #F5F5F5;
    max-width: 222px;
    min-width: 222px;
    max-height: 791px;
    min-height: 100%;
    padding: 20px 0;
    header {
      width: 100%;
      height: fit-content;
      background: transparent;
      margin-bottom: 16px;
      padding: 0;
      line-height: 22px;
      font-weight: 700;
      text-align: center;
    }
    main {
      padding: 0 10px;
      overflow-y: scroll;
      max-height: calc(100% - 109px);
    }
  }

  :global(.ant-divider) {
    margin: 11px 10px 15px 10px;
  }

  :global(.ant-table) {
    background: transparent;
    th {
      background: transparent;
      padding: 0;
      border: none;
      font-size: 12px;
      font-weight: 700;
      padding-bottom: 8px;
      &::before {
        display: none;
      }
    }
    tr {
      th:last-child {
        text-align: right;
      }
      td:first-child {
        width: 100px;
      }
      td:last-child {
        text-align: right;
      }
    }
    td {
      padding: 0;
      border: none;
      font-size: 12px;
      font-weight: 400;
      padding-bottom: 8px;
    }
  }

  .footer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-top: 16px;
    span {
      white-space: pre-wrap;
      font-size: 12px;
      text-align: right;
      color: #8C8C8C;
    }
  }

  :global(.ant-spin-spinning) {
    >span {
      font-size: 40px;
    }
    >div:last-child {
      padding-top: 40px;
      color: #000000;
    }
  }
}