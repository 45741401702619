.latest-order-container {
  @apply tw-border-gray-3 tw-border tw-p-5 tw-bg-white;

  .item-wrapper {
    @apply tw-flex tw-justify-start tw-text-gray-9;

    .label {
      @apply tw-header-5;
      width: 180px;
      padding-right: 44px;
    }
  }
}
