.container {
  :global(.ant-tag) {
    @apply tw-bg-gray-1 tw-text-gray-6 tw-border tw-border-gray-6;
  }

  :global(.ant-tag.guide) {
    @apply tw-bg-purple-1 tw-text-purple-3 tw-border tw-border-purple-3;
  }
  :global(.ant-tag.regular-contact) {
    @apply tw-bg-blue-sub1 tw-text-blue-primary tw-border tw-border-blue-primary;
  }
  :global(.ant-tag.general-schedule) {
    @apply tw-bg-green-1 tw-text-green-3 tw-border tw-border-green-3;
  }
  :global(.ant-tag.low-activity) {
    @apply tw-bg-red-1 tw-text-red-3 tw-border tw-border-red-3;
  }
}
