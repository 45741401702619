.repurchase-container {
  @apply tw-border-gray-3 tw-border tw-p-5 tw-bg-white tw-mt-5 tw-w-full;

  .item-wrapper {
    @apply tw-flex tw-justify-start tw-text-gray-9 tw-w-full tw-mb-2;

    .label {
      @apply tw-header-5 tw-whitespace-nowrap;
      width: 180px;
      padding-right: 44px;
    }

    .content {
      @apply tw-body-3 tw-w-full;
    }
  }

  .aggregation-period {
    width: 170px;
  }

  .aggregation-wrapper {
    @apply tw-w-full tw-mt-2;

    th {
      @apply tw-header-5 tw-py-3 tw-px-2.5 tw-text-left tw-bg-gray-1 tw-border-gray-3 tw-border tw-whitespace-nowrap;
      width: 170px;
      min-width: 170px;
    }
    td {
      @apply tw-body-3 tw-px-2.5 tw-text-left tw-border-gray-3 tw-border tw-w-full;
    }
  }
}
