.container {
  :global(.ant-pagination) {
    @apply tw-mt-4 tw-flex tw-justify-center;
  }

  :global(span.anticon.anticon-left) {
    vertical-align: 1px;
  }

  :global(span.anticon.anticon-right) {
    vertical-align: 1px;
  }
}
