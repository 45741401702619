.container {
  .col-updated {
    width: 110px;
  }
  .col-kind {
    min-width: 120px;
    width: 120px;
  }
  .col-action {
    width: 66px;
  }

  .tag-memo-kind {
    @apply tw-rounded-sm;

    &.active {
      @apply tw-bg-blue-sub1 tw-text-blue-primary tw-border-blue-sub5;
    }
    &.disabled {
      @apply tw-bg-gray-2 tw-text-gray-6 tw-border-gray-4;
    }
    &.inactive {
      @apply tw-bg-gray-2 tw-text-red-600;
    }
  }

  :global(.ant-table-cell) {
    @apply tw-py-3 tw-px-2.5;
  }

  :global(.col-content-wrapper) {
    max-height: 6.875rem;
    overflow-y: scroll;
  }

  :global(.col-content-wrapper::-webkit-scrollbar) {
    display: none;
  }
}
