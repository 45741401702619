.section-extra-action {
  @apply tw-flex tw-space-x-2.5;
}

.row-container {
  @apply tw-border-gray-3 tw-border tw-p-5 tw-bg-white tw-w-full;

  .item-wrapper {
    @apply tw-flex tw-justify-start tw-text-gray-9 tw-w-full tw-mb-2;

    .label {
      @apply tw-header-5 tw-whitespace-nowrap tw-w-56;
    }
  }
}

.alert {
  @apply tw-pl-5 tw-body-3 tw-text-red-3;
}
