.container {
  .input-kind {
    min-width: 170px;
    width: auto;
  }
  .input-content {
    min-width: 522px;
    width: initial;
  }
  :global(.ant-row.ant-form-item) {
    @apply tw-mb-3;
  }
}
