.customer-info {
  :global(.ant-form-inline) {
    @apply tw-justify-between;
  }

  :global(.ant-form-item-no-colon) {
    @apply tw-w-28 tw-header-5;
  }

  :global(.ant-form-item-control-input-content) {
    @apply tw-w-28 tw-body-3;
  }

  .body {
    @apply tw-px-5 tw-py-6 tw-mb-2.5 tw-border tw-border-gray-3;
  }

  .footer {
    @apply tw-flex tw-justify-end tw-space-x-2;
  }
}
