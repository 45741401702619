.base {
  @apply tw-rounded-sm;

  svg {
    @apply tw-align-baseline #{!important};
  }
}

.fill {
  @apply base
  tw-bg-blue-primary 
  tw-text-white 
  tw-border-0 
  hover:tw-bg-blue-sub5 
  active:tw-bg-blue-sub6 
  #{!important};
}

.fill-ghost {
  @apply base
  tw-text-blue-primary 
  tw-bg-white 
  tw-border
  tw-border-blue-sub4
  hover:tw-bg-blue-sub3
  active:tw-bg-blue-sub4 
  #{!important};

  &.red {
    @apply tw-text-red-3
    tw-border-red-3
    hover:tw-bg-red-1
    active:tw-bg-red-1 
    #{!important};
  }
}

.fill:disabled {
  @apply tw-bg-gray-2 
  tw-border 
  tw-border-gray-4 
  tw-text-gray-5 
  hover:tw-bg-gray-2 
  hover:tw-text-gray-5
  tw-cursor-not-allowed
  #{!important};
}

.fill-ghost:disabled {
  @apply tw-bg-gray-2 
  tw-border 
  tw-border-gray-4 
  tw-text-gray-5 
  hover:tw-bg-gray-2 
  hover:tw-text-gray-5
  tw-cursor-not-allowed
  #{!important};
}

.outline {
  @apply base
  tw-bg-transparent 
  tw-text-black 
  tw-border 
  tw-border-gray-4 
  tw-shadow-none
  hover:tw-bg-blue-sub5 
  hover:tw-text-white  
  active:tw-bg-blue-sub6 
  #{!important};
}

.outline:disabled {
  @apply tw-bg-gray-2 
  tw-border 
  tw-border-gray-4 
  tw-text-gray-5 
  hover:tw-bg-gray-2 
  hover:tw-text-gray-5
  tw-cursor-not-allowed
  #{!important};
}

.icon {
  @apply base
  tw-bg-transparent 
  tw-text-black 
  tw-border 
  tw-border-gray-4 
  tw-shadow-none
  hover:tw-bg-blue-sub5 
  hover:tw-text-white  
  active:tw-bg-blue-sub6 
  #{!important};
}

.icon:disabled {
  @apply tw-bg-gray-2 
  tw-border 
  tw-border-gray-4 
  tw-text-gray-5 
  hover:tw-bg-gray-2 
  hover:tw-text-gray-5
  tw-cursor-not-allowed
  #{!important};
}

.text {
  @apply base
  tw-border-0 
  tw-text-blue-primary
  tw-bg-transparent
  tw-shadow-none
  hover:tw-text-blue-sub5
  active:tw-text-blue-sub6
  active:tw-shadow-none
  #{!important};
}

.text:disabled {
  @apply tw-text-gray-5
  hover:tw-text-gray-5
  tw-cursor-not-allowed
  #{!important};
}

.full {
  @apply tw-w-full #{!important};
}

.large {
  @apply tw-h-auto tw-px-4 tw-py-2 tw-body-2 #{!important};
}

.normal {
  @apply tw-h-8 tw-px-2.5 tw-body-3 #{!important};
}

.label-bold {
  @apply tw-font-bold #{!important};
}
