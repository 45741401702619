.button-container {
  :global(.button-action-menu) {
    @apply tw-text-sm tw-font-bold tw-text-white tw-border-0 tw-w-full tw-p-0;
  }
  :global(.button-action-menu.new) {
    @apply tw-bg-green-2 #{!important};
  }
  :global(.button-action-menu.sent) {
    @apply tw-bg-red-2 #{!important};
  }
  :global(.button-action-menu.done) {
    @apply tw-bg-blue-sub3 #{!important};
  }
}
