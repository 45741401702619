.sync-button {
  @apply tw-border-gray-6 #{!important};
}

.fake-disable {
  @apply tw-bg-gray-2 
    tw-border 
    tw-border-gray-4 
    tw-text-gray-5 
    hover:tw-bg-gray-2 
    hover:tw-text-gray-5
    #{!important};
}
